import { useEffect, useRef } from 'react';
import { render } from 'react-dom';
const $ = jQuery;

import './style.scss';
import Style from './Style';
import { drags } from './utils/functions';

// Image Compare
document.addEventListener('DOMContentLoaded', () => {
	const allImageCompare = document.querySelectorAll('.wp-block-icb-image-compare');
	allImageCompare.forEach(imageCompare => {
		const attributes = JSON.parse(imageCompare.dataset.attributes);

		render(<>
			<Style attributes={attributes} clientId={attributes.cId} />

			<ImageCompare attributes={attributes} />
		</>, imageCompare);

		imageCompare?.removeAttribute('data-attributes');
	});
});

const ImageCompare = ({ attributes }) => {
	const { beforeImg, afterImg, isLabel, beforeLabel, afterLabel, isCap, cap } = attributes;

	const imgCompareRef = useRef(null);
	const dividerRef = useRef(null);
	const resizeRef = useRef(null);
	const beforeImgRef = useRef(null);

	const setImgWidth = width => {
		beforeImgRef.current.style.width = width;
		beforeImgRef.current.style.minWidth = width;
	}

	// Image Slider Scripts
	useEffect(() => {
		const imgCompareWidth = imgCompareRef.current?.clientWidth;

		const handleResize = () => setImgWidth(`${imgCompareWidth}px`);

		const observer = new ResizeObserver(handleResize);

		if (imgCompareRef.current) {
			observer.observe(imgCompareRef.current);

			setTimeout(() => {
				if (imgCompareRef.current) {
					handleResize();

					drags($(dividerRef.current), $(resizeRef.current), $(imgCompareRef.current));

					window.addEventListener('resize', handleResize);
				}
			}, 0);
		}

		return () => {
			observer.disconnect();
			window.removeEventListener('resize', handleResize);
		};
	}, [$]);

	return <div className='icbImageCompare' ref={imgCompareRef}>
		<div className='comparison-slider'>
			{isLabel && afterLabel && <div className='overlay afterLabel' dangerouslySetInnerHTML={{ __html: afterLabel }} />}
			<img className='afterImg' src={afterImg?.url} alt={afterImg?.alt} />

			<div className='resize' ref={resizeRef}>
				{isLabel && beforeLabel && <div className='overlay beforeLabel' dangerouslySetInnerHTML={{ __html: beforeLabel }} />}
				<img className='beforeImg' src={beforeImg?.url} alt={beforeImg?.alt} ref={beforeImgRef} />
			</div>

			<div className='divider' ref={dividerRef}></div>
		</div>

		{isCap && cap && <div className='caption' dangerouslySetInnerHTML={{ __html: cap }} />}
	</div>
}